import { render, staticRenderFns } from "./UserProspects.vue?vue&type=template&id=262b525c&scoped=true&"
import script from "./UserProspects.vue?vue&type=script&lang=ts&"
export * from "./UserProspects.vue?vue&type=script&lang=ts&"
import style0 from "./UserProspects.vue?vue&type=style&index=0&id=262b525c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "262b525c",
  null
  
)

export default component.exports